<template>
  <div id="Partner" class="w-screen h-screen relative overflow-hidden bg-slate-500">
    <div v-if="isLandscape" class="absolute w-full h-full left-0 top-0 flex justify-center items-center">
      <img src="./img/partner/ocean.jpg" class="absolute top-0 left-0 w-full h-full object-cover ocean z-0">
      <div class="relative w-full h-full z-10">
        <img src="./img/partner/framepartner.webp" class="absolute w-full h-full object-cover top-0 left-0 z-0">
        <div class="w-full h-full flex justify-center items-end">
          <div class="w-[57%] h-[60%] z-10  mb-[3%] flex flex-col justify-center items-center gap-[13%]">
            <div class="w-full h-fit flex justify-between items-center">
              <div class="w-[15%] aspect-square  partner no-before">
                <img src="./img/partner/partner_logo/logoc4k.webp" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">C4K Media</h1>
              </div>
              <div class="w-[15%] aspect-square  partner no-before">
                <img src="./img/partner/partner_logo/toncoin.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">TON</h1>
              </div>
              <div class="w-[15%] aspect-square  partner no-before">
                <img src="./img/partner/partner_logo/stonfi.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">STON.FI</h1>
              </div>
              <div @click="goPinksale()" class="w-[15%] aspect-square  partner">
                <img src="./pink.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">PINKSALE</h1>
              </div>
              <div @click="goDexview()" class="w-[15%] aspect-square  partner">
                <img src="./dex.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">DEXVIEW</h1>
              </div>
            </div>
            <div class="w-full h-fit flex justify-between items-center">
              <div class="w-[15%] aspect-square  partner">
                <img src="./img/partner/partner_logo/bitget.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">BITGET</h1>
              </div>
              <div class="w-[15%] aspect-square  partner">
                <img src="./img/partner/partner_logo/bitmart.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">BITMART</h1>
              </div>
              <div class="w-[15%] aspect-square  partner">
                <img src="./img/partner/partner_logo/mexc.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">MEXC</h1>
              </div>
              <div class="w-[15%] aspect-square  partner">
                <img src="./img/partner/partner_logo/gateio.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">GATE.IO</h1>
              </div>
              <div class="w-[15%] aspect-square partner">
                <img src="./img/partner/partner_logo/bybit.png" class="w-[70%]">
                <h1 class="absolute bottom-[-20%]">BYBIT</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="relative w-full h-full flex justify-center items-center">
      <div class="relative w-full h-full">
        <img src="./img/partner/mobile/backpartnermobile.jpg" class="w-full h-full object-cover">
        <img src="./img/partner/mobile/titlepartnermobile.webp"
          class="absolute w-full top-0 left-0 translate-y-[-25%] z-10">
        <div class="absolute w-full h-full top-0 left-0 flex justify-center items-center">
          <div class="w-full aspect-square mt-[10%]">
            <div class="w-[95%] h-full mx-auto flex justify-between">
              <div class="w-[30%] h-full flex justify-between items-center flex-col">
                <div class="w-full aspect-square partner-mobile no-before">
                  <img src="./img/partner/partner_logo/toncoin.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">TON</h1>
                </div>
                <div class="w-full aspect-square partner-mobile no-before">
                  <img src="./img/partner/partner_logo/stonfi.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">STON.FI</h1>
                </div>
              </div>
              <div @click="goPinksale()" class="w-full aspect-square partner-mobile no-before">
                  <img src="./pink.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">PINKSALE</h1>
                </div>
              <div class="w-[30%] h-full flex justify-between items-center flex-col">
                <div @click="goDexview()" class="w-full aspect-square partner-mobile">
                  <img src="./dex.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">DEXVIEW</h1>
                </div>
                <div class="w-full aspect-square partner-mobile">
                  <img src="./img/partner/partner_logo/bitget.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">BITGET</h1>
                </div>
                <div class="w-full aspect-square partner-mobile">
                  <img src="./img/partner/partner_logo/bitmart.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">BITMART</h1>
                </div>
              </div>
              <div class="w-[30%] h-full flex justify-between items-center flex-col">
                <div class="w-full aspect-square partner-mobile">
                  <img src="./img/partner/partner_logo/mexc.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">MEXC</h1>
                </div>
                <div class="w-full aspect-square partner-mobile">
                  <img src="./img/partner/partner_logo/gateio.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">GATE.IO</h1>
                </div>
                <div class="w-full aspect-square partner-mobile">
                  <img src="./img/partner/partner_logo/bybit.png" class="w-[70%]">
                  <h1 class="absolute bottom-[-22%]">BYBIT</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerList',
  props: {
    msg: String
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    }
  },
  methods: {
    goPinksale() {
      window.open('https://www.pinksale.finance', '_blank');
    },
    goDexview() {
      window.open('https://www.dexview.com', '_blank');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ocean {
  animation: ocean 10s infinite alternate;
}

@keyframes ocean {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(3%);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(3%);
  }

  100% {
    transform: translateY(0);
  }
}

.partner,
.partner-mobile {
  background-image: url(./img/partner/framelogopartner.webp);
  background-size: cover;
  filter: drop-shadow(5px 5px 15px #000000);
  -webkit-filter: drop-shadow(5px 5px 15px #000000);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner-mobile {
  filter: drop-shadow(3px 3px 10px #000000);
  -webkit-filter: drop-shadow(3px 3px 10px #000000);
}

.partner:hover {
  transform: scale(1.2);
}

.partner-mobile::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Caribbean', sans-serif;
  font-size: 15vw;
}

.partner::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Caribbean', sans-serif;
  font-size: 5vw;
}

.no-before::before {
  content: none;
}

.partner img,
.partner-mobile img {
  filter: drop-shadow(3px 3px 10px #000000);
  -webkit-filter: drop-shadow(3px 3px 10px #000000);
}

.partner h1 {
  font-size: 1vw;
  font-weight: bolder;
  color: #e3c68f;
  text-shadow: 1px 1px 4px #000000;
}

.partner-mobile h1 {
  font-size: 4vw;
  font-weight: bolder;
  color: #e3c68f;
  text-shadow: 1px 1px 4px #000000;
}
</style>
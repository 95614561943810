import { TonConnectUI } from '@tonconnect/ui';
const { Address, toNano } = require('@ton/core');
import axios from "axios";
let tonConnectUI;
let getWalletInterval = null;
let walletTimeout = null;

export function init() {
    tonConnectUI = new TonConnectUI({
        manifestUrl: 'https://grandjouneygame.vercel.app/tonconnect-manifest.json',
    });
    tonConnectUI.uiOptions = {
        actionsConfiguration: {
            twaReturnUrl: 'https://t.me/grandjourneybot/airdrop?startapp='
        }
    };
}

export function connect() {
    tonConnectUI.openModal();
}

export function disconnect() {
    tonConnectUI.disconnect();
}

export function getWalletAddress(callback) {
    if (getWalletInterval !== null) {
        return;
    }

    getWalletInterval = setInterval(() => {
        const rawAddress = tonConnectUI?.wallet?.account?.address;
        if (rawAddress) {
            const [workchainStr, addressStr] = rawAddress.split(':');
            const workchain = parseInt(workchainStr, 10);
            const addressBuffer = Buffer.from(addressStr, 'hex');

            const address = new Address(workchain, addressBuffer);
            const friendlyAddress = address.toString({
                testOnly: false,
                bounceable: false,
            });

            callback(friendlyAddress);
            clearInterval(getWalletInterval);
            getWalletInterval = null;
            clearTimeout(walletTimeout);
            walletTimeout = null;
        }
    }, 1000);

    walletTimeout = setTimeout(() => {
        clearInterval(getWalletInterval);
        getWalletInterval = null;
        callback(null);
    }, 600000);
}

export async function buyGrand(buyerAddress, amount) {
    const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
            {
                address:
                    "UQDgXMI1xx4U9KEGuiioXLlZPEEJcwKHLzSk_Q6tkLLXj4S-",
                amount: String(toNano(amount * 0.0002)),
                payload: "",
            },
        ],
    };
    try {
        const apiUrl = `https://grandjourney-f9f679650199.herokuapp.com/presale/buy?walletAddress=${encodeURIComponent(buyerAddress)}&amount=${encodeURIComponent(toNano(amount * 0.0002).toString())}&validUntil=${encodeURIComponent(transaction.validUntil)}`;
        console.log(apiUrl)
        let response = await axios.get(apiUrl);
        if (response.data.status == 'ok') {
            transaction.messages[0].payload = response.data.payload;
            await tonConnectUI.sendTransaction(transaction);
        }
    } catch (error) {
        console.error(error);
    }
}

init();
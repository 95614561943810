<template>
  <div id="roadmap" class="w-screen h-screen relative overflow-hidden bg-yellow-700">
    <div class="h-full w-full relative flex justify-center items-center">
      <img
        v-if="isLandscape"
        src="./img/roadmap/mapdesktop.webp"
        class="absolute w-full h-full object-cover"
      />
      <img
        v-else
        src="./img/roadmap/mobile/backmapmobile.jpg"
        class="absolute w-full h-full object-cover"
      />
      <div v-if="isLandscape" class="w-full aspect-video relative">
        <img src="./img/roadmap/lineroadmapdesktop.png" class="w-full h-full" />
        <div class="absolute top-[13%] left-0 w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island1.webp" class="w-full phase-img" />
          <h1
            class="absolute text-white bottom-[15%] left-[35%] font-caribbean custom-h1"
          >
            Phase 1 <br /><span>Q2-2024</span>
          </h1>
          <div
            class="absolute w-full aspect-video left-[20%] bottom-0 toast flex justify-center items-center"
          >
            <div
              class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content"
            >
              <h1>Phase 1 (Q2-2024)</h1>
              <span>1. Community building</span>
              <span>2. Website V1 release</span>
              <span>3. Launch Pirate-themed Gaming Center on Telegram</span>
              <span>4. Develop Grand Journey game</span>
            </div>
          </div>
        </div>
        <div class="absolute top-[53%] left-[2%] w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island3.webp" class="w-full phase-img" />
          <h1 class="absolute text-white top-[20%] right-[15%] font-caribbean custom-h1">
            Phase 2 <br /><span>Q3-2024</span>
          </h1>
          <div
            class="absolute w-full aspect-video left-[20%] top-0 toast flex justify-center items-center"
          >
            <div
              class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content"
            >
              <h1>Phase 2 (Q3-2024)</h1>
              <span>1. Release Grand Journey game on Telegram</span>
              <span>2. Develop the Lucky Pirate Wheel for Grand Journey</span>
              <span>3. Develop the Staking feature for Grand Journey</span>
            </div>
          </div>
        </div>
        <div class="absolute top-[12%] left-[23%] w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island5.webp" class="w-full phase-img" />
          <h1
            class="absolute text-white bottom-[13%] left-[10%] font-caribbean custom-h1"
          >
            Phase 3 <br /><span>Q4-2024</span>
          </h1>
          <div
            class="absolute w-full aspect-video right-[10%] bottom-0 toast flex justify-center items-center"
          >
            <div
              class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content"
            >
              <h1>Phase 3 (Q4-2024)</h1>
              <span>1. Develop NFT features for Grand Journey</span>
              <span>2. Available on Telegram Apps Center</span>
              <span>3. Launch of ecosystem-specific wallet</span>
              <span>4. Presale Event Launch</span>
            </div>
          </div>
        </div>
        <div class="absolute top-[22%] left-[48%] w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island2.webp" class="w-full phase-img" />
          <h1
            class="absolute text-white bottom-[-10%] left-[40%] font-caribbean custom-h1"
          >
            Phase 5 <br /><span>Q2-2025</span>
          </h1>
          <div
            class="absolute w-full aspect-video left-0 bottom-[15%] toast flex justify-center items-center"
          >
            <div
              class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content"
            >
              <h1>Phase 5 (Q2-2025)</h1>
              <span>1. Release new games in the ecosystem</span>
              <span>2. Airdrop 2 to attract more users</span>
              <span>3. Listing on OKX, Binance</span>
              <span>4. NFT Trading Marketplace Release and Item Trading Feature</span>
            </div>
          </div>
        </div>
        <div class="absolute top-[52%] left-[32%] w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island6.webp" class="w-full phase-img" />
          <h1 class="absolute text-white top-[30%] right-0 font-caribbean custom-h1">
            Phase 4 <br /><span>Q1-2025</span>
          </h1>
          <div
            class="absolute w-full aspect-video left-0 top-0 toast flex justify-center items-center"
          >
            <div
              class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content"
            >
              <h1>Phase 4 (Q1-2025)</h1>
              <span>1. Big Marketing Campaign</span>
              <span>2. Community Airdrop Event</span>
              <span>3. Listing on DEX, Bitmart, BingX, Gate, Bitget</span>
            </div>
          </div>
        </div>
        <div class="absolute top-[53%] left-[68%] w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island4.webp" class="w-full phase-img" />
          <h1 class="absolute text-white top-[40%] left-[10%] font-caribbean custom-h1">
            Phase 6 <br /><span>Q3-2025</span>
          </h1>
          <div
            class="absolute w-full aspect-video left-0 top-0 toast flex justify-center items-center"
          >
            <div
              class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content"
            >
              <h1>Phase 6 (Q3-2025)</h1>
              <span>Roadmap V2</span>
            </div>
          </div>
        </div>
        <!-- <div class="absolute top-0 left-[75%] w-[25%] aspect-video phase">
          <img src="./img/roadmap/phase/island7.webp" class="w-full phase-img">
          <h1 class="absolute text-white bottom-0 left-[25%] font-caribbean custom-h1">Phase 7
            <br><span>Q4-2025</span>
          </h1>
          <div class="absolute w-full aspect-video right-[25%] bottom-[10%] toast flex justify-center items-center">
            <div class="w-[85%] h-[70%] flex flex-col justify-start items-start toast-content">
              <h1>Phase 7 (Q4-2025)</h1>
              <span>Coming soon</span>
            </div>
          </div>
        </div> -->
      </div>
      <div id="roadmap_mobile" v-else class="w-full h-[85%] relative">
        <img src="./img/roadmap/mobile/lineroadmapmobile.png" class="h-full w-full" />
        <img
          @click="showToast(1)"
          src="./img/roadmap/phase/island1.webp"
          class="absolute w-[50%] top-[-5%] left-0"
        />
        <img
          @click="showToast(2)"
          src="./img/roadmap/phase/island2.webp"
          class="absolute w-[50%] top-[13%] right-0"
        />
        <img
          @click="showToast(3)"
          src="./img/roadmap/phase/island3.webp"
          class="absolute w-[50%] top-[20%] left-0"
        />
        <img
          @click="showToast(4)"
          src="./img/roadmap/phase/island4.webp"
          class="absolute w-[50%] top-[30%] right-0"
        />
        <img
          @click="showToast(5)"
          src="./img/roadmap/phase/island5.webp"
          class="absolute w-[45%] top-[45%] left-[-5%]"
        />
        <img
          @click="showToast(6)"
          src="./img/roadmap/phase/island6.webp"
          class="absolute w-[45%] top-[60%] right-0"
        />
        <img
          src="./img/roadmap/phase/island7.webp"
          class="absolute w-[40%] top-[70%] left-0"
        />
        <h1
          @click="showToast(1)"
          class="absolute top-[10%] left-[15%] custom-h1-mobile font-caribbean"
        >
          Phase 1
        </h1>
        <h1
          @click="showToast(2)"
          class="absolute top-[22%] right-[7%] custom-h1-mobile font-caribbean"
        >
          Phase 2
        </h1>
        <h1
          @click="showToast(3)"
          class="absolute top-[35%] left-[28%] custom-h1-mobile font-caribbean"
        >
          Phase 3
        </h1>
        <h1
          @click="showToast(4)"
          class="absolute top-[49%] right-[25%] custom-h1-mobile font-caribbean"
        >
          Phase 4
        </h1>
        <h1
          @click="showToast(5)"
          class="absolute top-[62%] left-[16%] custom-h1-mobile font-caribbean"
        >
          Phase 5
        </h1>
        <h1
          @click="showToast(6)"
          class="absolute top-[75%] right-[18%] custom-h1-mobile font-caribbean"
        >
          Phase 6
        </h1>
        <!-- <h1 @click="showToast(7)" class="absolute top-[83%] left-[22%] custom-h1-mobile font-caribbean">Phase 7</h1> -->
      </div>
      <div
        ref="toast"
        @click="hideToast"
        class="absolute w-full h-full top-0 left-0 flex justify-center items-center backdrop-blur-md scale-0"
      >
        <div
          ref="toast_detail"
          class="w-full aspect-video relative transition-all scale-0"
        >
          <img src="./img/roadmap/framedetail.webp" class="w-full h-full" />
          <div
            class="absolute w-full h-full left-0 top-0 flex justify-center items-center"
          >
            <div class="h-[90%] w-[90%]">
              <span ref="toast_content"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoadMap",
  props: {
    msg: String,
  },
  computed: {
    isLandscape() {
      return window.innerWidth > window.innerHeight;
    },
  },
  methods: {
    showToast(index) {
      console.log(index);
      this.$refs.toast.classList.add("scale-[1]");
      this.$refs.toast_detail.classList.add("scale-[1]");
      if (index == 1) {
        this.$refs.toast_content.innerHTML =
          "Phase 1 (Q2-2024) <br> 1. Community building <br> 2. Website V1 release <br> 3. Launch Pirate-themed Gaming Center on Telegram <br> 4. Develop Grand Journey game";
      }
      if (index == 2) {
        this.$refs.toast_content.innerHTML =
          "Phase 2 (Q3-2024) <br> 1. Release Grand Journey game on Telegram <br> 2. Develop the Lucky Pirate Wheel for Grand Journey <br> 3. Develop the Staking feature for Grand Journey";
      }
      if (index == 3) {
        this.$refs.toast_content.innerHTML = "Phase 3 (Q4-2024) <br> 1. Develop NFT features for Grand Journey <br> 2. Available on Telegram Apps Center <br> 3. Launch of ecosystem-specific wallet <br> 4. Presale Event Launch"
      }
      if(index == 4) {
        this.$refs.toast_content.innerHTML = "Phase 4 (Q1-2025) <br> 1. Big Marketing Campaign <br> 2. Community Airdrop Event <br> 3. Listing on DEX, Bitmart, BingX, Gate, Bitget"
      }
      if(index == 5){
        this.$refs.toast_content.innerHTML = "Phase 5 (Q2-2025) <br> 1. Release new games in the ecosystem <br> 2. Airdrop 2 to attract more users <br> 3. Listing on OKX, Binance <br> 4. NFT Trading Marketplace Release and Item Trading Feature"
      }
      if(index == 6){
        this.$refs.toast_content.innerHTML = "Phase 6 (Q3-2025) <br> Roadmap V2"
      }
    },
    hideToast() {
      this.$refs.toast.classList.remove("scale-[1]");
      this.$refs.toast.classList.add("scale-0");
      this.$refs.toast_detail.classList.remove("scale-[1]");
      this.$refs.toast_detail.classList.add("scale-0");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.phase {
  cursor: pointer;
}

.phase .phase-img {
  transition: all 0.3s ease;
  filter: drop-shadow(5px 5px 15px #dd7621);
}

.phase:hover .phase-img {
  transform: scale(1.2);
  -webkit-filter: drop-shadow(5px 5px 15px #f5cf84);
  filter: drop-shadow(5px 5px 15px #f5cf84);
}

.toast {
  background-image: url(./img/roadmap/framedetail.webp);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 40;
  transition: all 0.5s ease;
  transform: scale(0);
  -webkit-filter: drop-shadow(0px 0px 100px #000000);
  filter: drop-shadow(0px 0px 100px #000000);
}

.phase:hover .toast {
  transform: scale(1);
}

.custom-h1 {
  color: #c0a87a;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  font-size: 30px;
  line-height: 1;
}

.custom-h1 span {
  color: #977259;
  font-size: 20px;
}

.toast-content h1,
.toast-content span {
  line-height: 1;
}

.toast-content h1 {
  color: #48291b;
  font-size: clamp(1vw, 1.3vw, 2vw);
  font-weight: bolder;
  text-shadow: 1px 1px 2px #fff;
  padding-bottom: 10px;
}

.toast-content span {
  padding-left: 10px;
  font-size: clamp(0.5vw, 0.8vw, 1vw);
}

.custom-h1-mobile {
  color: #97623f;
  font-size: 5.8vw;
  line-height: 1;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  transition: all 0.3s ease;
}

.custom-h1-mobile:active {
  transform: scale(0.9);
}

#roadmap_mobile img {
  animation: filter-color 2s infinite alternate;
}

@keyframes filter-color {
  from {
    -webkit-filter: drop-shadow(2px 2px 5px #f56e00);
    filter: drop-shadow(2px 2px 5px #f56e00);
  }

  50% {
    -webkit-filter: drop-shadow(3px 3px 10px #f56e00);
    filter: drop-shadow(3px 3px 10px #f56e00);
  }

  to {
    -webkit-filter: drop-shadow(5px 5px 15px #f56e00);
    filter: drop-shadow(5px 5px 15px #f56e00);
  }
}
</style>

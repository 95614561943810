<template>
  <div id="c4kmedia" class="w-screen h-screen relative overflow-hidden flex justify-center items-center">
    <div class="absolute w-full h-full top-0 left-0">
      <img src="../../assets/img/c4k/backball.jpg" class="w-full h-full object-cover">
    </div>
    <canvas id="canvas1"></canvas>
  </div>
</template>

<script>
export default {
  name: 'AboutC4KMedia',
  props: {
    msg: String
  },
  mounted() {
    this.create();
  },
  methods: {
    create() {
      const canvas = document.getElementById('canvas1');
      const ctx = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      class Particle {
        constructor(effect, i) {
          this.effect = effect;
          this.index = i;
          this.radius = Math.floor(Math.random() * 50 + 10);
          if (this.index % 50 === 0) this.radius = Math.floor(Math.random() * 20 + 100);
          this.x = this.radius + Math.random() * (this.effect.width - this.radius * 2);
          this.y = this.radius + Math.random() * (this.effect.height - this.radius * 2);
          this.vx = Math.random() * 1 - 1;
          this.vy = Math.random() * 1 - 1;
          this.pushX = 0;
          this.pushY = 0;
          this.friction = Math.random() * 0.4 + 0.2;
          this.color = '#ffe7bb';
          this.images = [
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
            new Image(),
          ];
          this.images[0].src = require('../../assets/img/c4k/ball1.webp');
          this.images[1].src = require('../../assets/img/c4k/ball2.webp');
          this.images[2].src = require('../../assets/img/c4k/ball3.webp');
          this.images[3].src = require('../../assets/img/c4k/ball4.webp');
          this.images[4].src = require('../../assets/img/c4k/ball5.webp');
          this.images[5].src = require('../../assets/img/c4k/ball6.webp');
          this.images[6].src = require('../../assets/img/c4k/ball9.png');
          this.images[7].src = require('../../assets/img/c4k/ball10.png');
          this.images[8].src = require('../../assets/img/c4k/ball11.png');
          this.images[9].src = require('../../assets/img/c4k/ball12.png');
          this.images[10].src = require('../../assets/img/c4k/ball13.png');
          this.images[11].src = require('../../assets/img/c4k/ball14.png');
          this.images[12].src = require('../../assets/img/c4k/ball15.png');
          this.images[13].src = require('../../assets/img/c4k/ball16.png');
          this.images[14].src = require('../../assets/img/c4k/ball17.png');

          this.image = this.images[Math.floor(Math.random() * this.images.length)]; // Randomly assign image
        }
        draw(context) {
          // context.fillStyle = this.color;
          // context.beginPath();
          // context.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
          // context.fill();

          // context2.fillStyle = 'red';
          // context2.beginPath();
          // context2.arc(this.x, this.y, this.radius * 0.9, 0, Math.PI * 2);
          // context2.fill();

          if (this.image.complete) {
            context.drawImage(this.image, this.x - this.radius, this.y - this.radius, this.radius * 2, this.radius * 2);
          } else {
            this.image.onload = () => {
              context.drawImage(this.image, this.x - this.radius, this.y - this.radius, this.radius * 2, this.radius * 2);
            }
          }
        }
        update() {
          // mouse interactions
          const dx = this.x - this.effect.mouse.x;
          const dy = this.y - this.effect.mouse.y;
          const distance = Math.hypot(dx, dy);
          const force = (this.effect.mouse.radius / distance);
          if (distance < this.effect.mouse.radius) {
            const angle = Math.atan2(dy, dx);
            this.pushX += Math.cos(angle) * force;
            this.pushY += Math.sin(angle) * force;
          }

          // collision with other particles
          this.effect.particles.forEach(particle => {
            if (particle.index !== this.index) {
              const dx = this.x - particle.x;
              const dy = this.y - particle.y;
              const distance = Math.hypot(dx, dy);
              const sumOfRadii = particle.radius + this.radius;
              if (distance < sumOfRadii) {
                // Resolve overlap
                const overlap = sumOfRadii - distance;
                const unit_x = dx / distance;
                const unit_y = dy / distance;
                this.x += unit_x * overlap / 2;
                this.y += unit_y * overlap / 2;
                particle.x -= unit_x * overlap / 2;
                particle.y -= unit_y * overlap / 2;

                // Swap velocities
                const tempVx = this.vx;
                const tempVy = this.vy;
                this.vx = particle.vx;
                this.vy = particle.vy;
                particle.vx = tempVx;
                particle.vy = tempVy;
              }
            }
          });

          // boundaries
          if (this.x < this.radius) {
            this.x = this.radius;
            this.vx *= -1;
          } else if (this.x > this.effect.width - this.radius) {
            this.x = this.effect.width - this.radius;
            this.vx *= -1;
          }
          if (this.y < this.radius) {
            this.y = this.radius;
            this.vy *= -1;
          } else if (this.y > this.effect.height - this.radius) {
            this.y = this.effect.height - this.radius;
            this.vy *= -1;
          }

          this.x += (this.pushX *= this.friction) + this.vx;
          this.y += (this.pushY *= this.friction) + this.vy;
        }
        reset() {
          this.x = this.radius + Math.random() * (this.effect.width - this.radius * 2);
          this.y = this.radius + Math.random() * (this.effect.height - this.radius * 2);
        }
      }

      class Effect {
        constructor(canvas, context) {
          this.canvas = canvas;
          this.context = context;
          this.width = this.canvas.width;
          this.height = this.canvas.height;
          this.particles = [];
          if (this.width < 640) {
            this.numberOfParticles = 20;
          } else if (this.width < 1025) {
            this.numberOfParticles = 50;
          } else {
            this.numberOfParticles = 70;
          }
          this.createParticles();

          this.mouse = {
            x: 0,
            y: 0,
            radius: 200
          };

          window.addEventListener('resize', e => {
            this.resize(e.target.window.innerWidth, e.target.window.innerHeight);
          });
          window.addEventListener('mousemove', e => {
            this.mouse.x = e.x;
            this.mouse.y = e.y;
          });
          window.addEventListener('mousedown', e => {
            this.mouse.x = e.x;
            this.mouse.y = e.y;
          });

        }
        createParticles() {
          for (let i = 0; i < this.numberOfParticles; i++) {
            this.particles.push(new Particle(this, i));
          }
        }
        handleParticles(context) {
          this.connectParticles(context);
          this.particles.forEach(particle => {
            particle.draw(context);
            particle.update();
          });
        }
        connectParticles(context) {
          const maxDistance = 150;
          for (let a = 0; a < this.particles.length; a++) {
            for (let b = a; b < this.particles.length; b++) {
              const dx = this.particles[a].x - this.particles[b].x;
              const dy = this.particles[a].y - this.particles[b].y;
              const distance = Math.hypot(dx, dy);
              if (distance < maxDistance) {
                context.save();
                const opacity = 1 - (distance / maxDistance);
                context.strokeStyle = this.particles[a].color;
                context.globalAlpha = opacity;
                context.beginPath();
                context.moveTo(this.particles[a].x, this.particles[a].y);
                context.lineTo(this.particles[b].x, this.particles[b].y);
                context.stroke();
                context.restore();
              }
            }
          }
        }
        resize(width, height) {
          this.canvas.width = width;
          this.canvas.height = height;
          this.width = width;
          this.height = height;
          this.particles.forEach(particle => {
            particle.reset();
          });
        }
      }
      const effect = new Effect(canvas, ctx);

      function animate() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        effect.handleParticles(ctx);
        requestAnimationFrame(animate);
      }
      animate();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  cursor: pointer;
}
</style>
